<template lang="pug">
  .account-list
    #tooltip-container
    TopBar(
      :organizations="organizations"
      :organizations-loading="organizationsLoading"
      :filters="filters"
      @change-filters="applyFilters"
    )
    .accounts-table-wrapper
      AppOverlayLoader(:state="accountsLoading && !$root.modalOpened")
      .accounts-table
        VueGoodTable(
          :rows="accountsList"
          :columns="columns"
          :sort-options="{ initialSortBy: sorting }"
          :key="isOrganizationsAdminList"
          @on-sort-change="handleSorting"
        )
          template(v-slot:table-column="props")
            span(:title="props.column.label") {{ $t(props.column.label) }}
          template(v-slot:table-row="{ column, row, formattedRow }")
            .account-id(v-if="column.field === 'account_id'")
              AppTextExpandable(
                :key="`username-account-id-${row.id}`"
                :text="formattedRow.account_id"
              )
            .username(v-else-if="column.field === 'full_name'")
              AppTextExpandable(
                :key="`username-full-name-${row.id}`"
                :text="formattedRow.full_name"
              )
            .ota(v-else-if="column.field === 'ota'")
              span {{ translatedOtaNamesMapping[row.ota_id] }}
            .role(v-else-if="column.field === 'role'")
              #tooltip-container
              .warning(v-if="isEmpty(row.role)")
                AppTooltip(
                  icon="exclamation-triangle"
                  type="danger"
                  :title="noUserRoleTip"
                  container="#tooltip-container"
                )
              span(v-else) {{ row.role.name }}
            .table-actions(
              v-else-if="column.field === 'actions'"
              v-b-tooltip.noninteractive.hover="{ title: $t('account_management.account_list.inactive_in_db'), placement: 'center', disabled: row.active && row.organization.active }"
            )
              AppIconButton.action-button(
                :disabled="!(row.active && row.organization.active)"
                icon="eye"
                @click="impersonate(row)"
              )
              AppIconButton.action-button(icon="info-circle", @click="openModal(row)")
              AppIconButton.action-button(
                v-if="isOrganizationsAdminList || isOtaAdminList"
                icon="edit"
                @click="editUser(row)"
              )
              AppIconButton.action-button(
                :class="{ disabled: !row.editable }"
                icon="trash-alt"
                @click="deleteUser(row)"
              )
      AppPagination(
        :current-page="pagination.current_page"
        :total="pagination.total_count",
        :per-page="pagination.per_page"
        :auto-scroll-on-pagination="{ container: '.accounts-table' }"
        @change-pagination-data="changePagination"
      )
</template>

<script>
  // store modules
  import accountsModule from "@/config/store/maestro/accounts"
  import organizationsModule from "@/config/store/maestro/organizations"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withPermissions from "@/mixins/withPermissions"
  import withModal from "@/mixins/withModal"
  import withConfirmation from "@/mixins/withConfirmation"
  import withOrganizationAdminList from "@/mixins/withOrganizationAdminList"
  import withOtaAdminList from "@/mixins/withOtaAdminList"

  // components
  import { VueGoodTable } from "vue-good-table"

  // misc
  import { columns as accountsTableColumns } from "./AccountsTableConfig"
  import { columns as organizationAdminTableColumns } from "./OrganizationsAdminsTableConfig"
  import { columns as otaAdminTableColumns } from "./OtaAdminsTableConfig"
  import { api } from "@/config"
  import { mapState, mapGetters } from "vuex"
  import { isEmpty, isEqual } from "lodash-es"
  import { showToast } from "@/helpers/toasts"
  import "vue-good-table/dist/vue-good-table.css"

  const accountsMixin = withStoreModule(accountsModule, {
    name: "maestro_accounts",
    resetState: true,
    readers: {
      accounts: "items",
      accountsLoading: "loading",
      filters: "filters",
      pagination: "pagination",
      sorting: "sorting"
    },
    actions: { fetchAccounts: "FETCH_ITEMS", deleteAccount: "DELETE_ITEM" },
    mutations: {
      setFilters: "SET_FILTERS",
      setPagination: "SET_PAGINATION_DATA",
      setSorting: "SET_SORTING",
      resetFilters: "RESET_FILTERS",
      resetSorting: "RESET_SORTING"
    }
  })

  const organizationsMixin = withStoreModule(organizationsModule, {
    name: "accountManagementOrganizations",
    readers: { organizations: "items", organizationsLoading: "loading" },
    actions: { fetchOrganizations: "FETCH_ITEMS" }
  })

  export default {
    components: {
      VueGoodTable,
      TopBar: () => import("./TopBar"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppTextExpandable: () => import("@/components/elements/AppTextExpandable"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppTooltip: () => import("@/components/elements/AppTooltip"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [
      accountsMixin,
      organizationsMixin,
      withPermissions,
      withModal,
      withConfirmation,
      withOtaAdminList,
      withOrganizationAdminList
    ],

    mounted() {
      this.$store.dispatch("FETCH_OTA_LIST")
      this.setFilters({
        ota_admin: this.isOtaAdminList,
        organization_admin: this.isOrganizationsAdminList
      })
      if (this.currentUser.organization_admin) {
        this.setSorting({ field: "users_organizations_order", direction: "asc" })
      }
      isEmpty(this.organizations) && this.fetchOrganizations({ pagination: { _disabled: true } })
      isEmpty(this.accounts) && this.fetchAccounts()
    },

    computed: {
      ...mapGetters(["translatedOtaNamesMapping"]),

      ...mapState(["currentUser"]),

      accountsTableColumns,

      otaAdminTableColumns,

      organizationAdminTableColumns,

      columns() {
        if (this.isOrganizationsAdminList) {
          return this.organizationAdminTableColumns
        } else if (this.isOtaAdminList) {
          return this.otaAdminTableColumns
        } else {
          return this.accountsTableColumns
        }
      },

      accountsList() {
        return this.accounts || []
      },

      noUserRoleTip() {
        return this.$t("account_management.account_list.no_user_role")
      }
    },

    methods: {
      isEmpty,

      async impersonate({ id }) {
        try {
          await api.post(`/maestro/users/${id}/impersonate`, {}, { skipErrorToast: true })
          window.location.replace("/")
        } catch (err) {
          showToast({ text: err.response.data.common })
        }
      },

      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.fetchAccounts()
      },

      applyFilters(filters) {
        this.setFilters(filters)
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.fetchAccounts()
      },

      deleteUser(user) {
        if (user.editable && this.currentUser.id !== user.id) {
          this.$confirm({
            title: this.$t("accounts.delete_confirmation", { name: user.account_id }),
            resolve: {
              handler: async () => {
                await this.deleteAccount(user.id)
                this.fetchAccounts()
              }
            }
          })
        }
      },

      handleSorting([sortingData]) {
        if (isEqual(sortingData, this.sorting)) return

        this.setSorting(sortingData)
        this.fetchAccounts()
      },

      openModal(row) {
        this.$openModal({
          title: this.$t("header.title.account_info"),
          component: () => import("@/pages/Maestro/AccountInfoModal"),
          size: "large",
          props: {
            currentAccount: row
          }
        })
      },

      editUser(row) {
        this.$openModal({
          component: () => import("./UserForm"),
          title: this.$t("account_management.account_list.edit_account"),
          props: {
            currentAccount: row
          }
        })
      }
    },

    watch: {
      $route() {
        this.resetSorting()
        this.resetFilters()
        this.setFilters({
          ota_admin: this.isOtaAdminList,
          organization_admin: this.isOrganizationsAdminList
        })
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.fetchAccounts()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/tables/vue-good-table.sass"

  .app-overlay-loader
    z-index: 1

  #tooltip-container
    +tooltip($default-red)

  .accounts-table-wrapper
    position: relative

    ::v-deep
      .accounts-table
        +listing-container(13px)
        +app-vue-table

        .vgt-table
          border: none
          width: 100%

          .account-id-field
            width: fit-content

          .last-login-at-field
            width: fit-content

          .expandable-field
            max-width: 200px

          .vgt-right-align
            // overwrite text align for date fields
            text-align: left

          th
            background: $th-background-color
            border-bottom: 1px solid $default-purple-light-line
            border-left: 1px solid $border-element-color
            border-right: 1px solid $border-element-color
            border-top: none
            color: $default-purple
            font-size: 0.8rem
            font-weight: 700
            padding: 17px 7px

            &:first-child
              border-left: 1px solid $default-purple-light-line

            &:last-child
              border-right: 1px solid $default-purple-light-line

          td
            border-bottom: 1px solid $default-purple-light-line
            border-left: 1px solid $border-element-color
            border-right: 1px solid $border-element-color
            border-top: 1px solid $default-purple-light-line
            color: $td-color
            font-size: 0.8rem
            padding: 11px 7px 13px
            max-width: 200px

            &:first-child
              border-left: 1px solid $default-purple-light-line

            &:last-child
              border-right: 1px solid $default-purple-light-line

            &.actions-field
              width: 1%
              min-width: 70px
              white-space: nowrap

            .role
              .warning
                text-align: center

                .app-tooltip
                  margin: 0

            .table-actions
              text-align: center

              .app-icon
                fill: $default-purple
                height: 18px
                width: 18px

              .action-button
                +icon-button($default-purple)

                svg
                  margin: 0
</style>
