import { api, i18n } from "@/config"
import { showToast } from "@/helpers/toasts"
import { normalizeSortingParams } from "@/helpers/vue-good-table"
import { mapFilters } from "./index"

export default ({ baseURI }) => ({
  FETCH_ITEM: async ({ commit }, id) => {
    try {
      const response = await api.get(`${baseURI}/${id}`)

      commit("SET_ITEM", response.data.data)
    } catch (e) {
      throw e
    }
  },

  CREATE_ITEM: async (_, params) => {
    try {
      await api.post(baseURI, { user: params })
    } catch (e) {
      showToast({ text: i18n.t("activerecord.errors.models.user.could_not_create") })
      throw e
    }
  },

  UPDATE_ITEM: async (_, params) => {
    try {
      await api.put(`${baseURI}/${params.id}`, { user: params })
    } catch (e) {
      showToast({ text: i18n.t("activerecord.errors.models.user.could_not_update") })
      throw e
    }
  },

  DELETE_ITEM: async ({ state: { pagination, sorting, filters } }, id) => {
    try {
      const params = {
        pagination,
        sorting: normalizeSortingParams(sorting),
        filters: mapFilters(filters)
      }
      await api.delete(`${baseURI}/${id}`, { params })
    } catch ({ response: { status } }) {
      if (status === 404) showToast({ text: i18n.t("activerecord.errors.models.user.not_found") })
    }
  },

  CHECK_ACCOUNT_ID_EXISTS: (_, account_id) => {
    return api.post(`${baseURI}/account_exists`, { account_id })
  }
})
