import { configPrepare } from "@/helpers/vue-good-table"
import { store } from "@/config"

const defaultTableProperties = {
  tdClass: "accounts-table-td",
  thClass: "accounts-table-th",
  sortable: true
}

const i18nPrefix = "accounts.table.columns"

const fields = [
  {
    field: "account_id",
    thClass: "accounts-table-th account-id-field",
    tdClass: "accounts-table-td account-id-field"
  },
  {
    label: `${i18nPrefix}.organization`,
    field: "organization.name",
    sortable: false
  },
  {
    label: `${i18nPrefix}.role`,
    field: "role"
  },
  {
    field: "full_name",
    thClass: "accounts-table-th expandable-field",
    tdClass: "accounts-table-td expandable-field"
  },
  {
    field: "current_sign_in_at",
    formatFn: current_sign_in_at => current_sign_in_at || "—",
    thClass: "accounts-table-th last-login-at-field",
    tdClass: "accounts-table-td last-login-at-field"
  },
  {
    field: "active",
    formatFn: active => String(!!active),
    thClass: "accounts-table-th",
    tdClass: "accounts-table-td",
    sortable: false,
    hidden: store.state.currentUser.organization_admin
  },
  {
    label: "",
    field: "actions",
    tdClass: "accounts-table-td text-right actions-field",
    sortable: false
  },
  {
    label: "",
    field: "users_organizations_order",
    hidden: true
  }
]

export const columns = () => configPrepare(fields, { i18nPrefix, defaults: defaultTableProperties })
